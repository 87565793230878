import './App.css';

function App() {
  return (
    <div className="App">
        <img src="https://educacional.s3.sa-east-1.amazonaws.com/mamore%CC%81+educacional.png" />
    </div>
  );
}

export default App;
